<template>
  <v-app-bar color="secondary" dark app flat>
    <v-app-bar-nav-icon @click="toggleL"></v-app-bar-nav-icon>
    <v-toolbar-title v-html="title"></v-toolbar-title>
    <v-spacer></v-spacer>
    <template>
      <v-btn
        v-if="isMultiRole"
        :elevation="1"
        rounded
        color="white"
        small
        light
        class="primary--text mr-2"
        @click="popup('role')"
      >
        <v-icon small class="primary--text">mdi-account-switch</v-icon>
        <span class="d-none d-sm-block">Ubah Peran</span>
      </v-btn>
      <v-divider vertical inset dark></v-divider>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-menu v-model="menu" left :close-on-content-click="false" offset-y :nudge-width="200">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  :elevation="1"
                  rounded
                  color="white"
                  small
                  light
                  class="primary--text mx-2"
                  v-bind="attrs"
                  @click="onLayanans"
                >
                  <v-icon>mdi-apps</v-icon>
                  <span class="d-none d-sm-block">Program/Layanan</span>
                </v-btn>
              </template>
              <v-card>
                <v-list dense>
                  <template v-for="(item, i) in layanans">
                    <v-list-item :key="i" :href="item.url">
                      <v-list-item-avatar size="22">
                        <v-icon color="success" dense>mdi-text-box-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="caption" v-html="item.nama"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>

                <h5 v-if="arsips.length" class="px-5 py-2" style="border-bottom: 1px solid #eee">ARSIP LAYANAN</h5>
                <v-list dense>
                  <template v-for="(item, i) in arsips">
                    <v-list-item :key="i" :href="item.url">
                      <v-list-item-avatar size="22">
                        <v-icon color="error" dense>mdi-text-box-remove-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="caption" v-html="item.nama"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </template>
        <span>Program Lain</span>
      </v-tooltip>
      <v-list color="transparent">
        <v-list-item @click="toggleR" class="pa-0 ma-0">
          <v-list-item-avatar :size="32" color="grey lighten-4" class="ma-0 mr-1">
            <v-img :src="$imgUrl(avatar)" :aspect-ratio="4 / 6" class="grey lighten-2"></v-img>
          </v-list-item-avatar>
          <v-list-item-title class="d-none d-sm-block">
            {{ username }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <popup-peran ref="role"></popup-peran>
  </v-app-bar>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    PopupPeran: () => import('../popup/Perans'),
  },
  data: () => ({
    menu: false,
    show: false,
    layanans: [],
    arsips: [],
  }),
  computed: {
    ...mapState('auth', {
      role: (state) => state.role ?? '',
      env: (state) => state.env ?? '',
      authAkun: (state) => state.akun ?? '',
      authPtk: (state) => state.ptk ?? '',
    }),

    ...mapState('preferensi', {
      ptk: (state) => state?.data?.ptk ?? {},
      akun: (state) => state?.data?.akun ?? {},
    }),

    title() {
      return (this.$route.meta && this.$route.meta.title) || 'Beranda';
    },

    username() {
      return this.role === 'instansi' ? this.akun?.nama ?? 'Admin' : this.ptk?.nama ?? 'Gtk';
    },

    avatar() {
      return this.role === 'instansi' ? this.akun?.foto ?? 'avatar.png' : this.ptk?.foto ?? 'avatar.png';
    },

    isMultiRole() {
      return !!Object.keys(this.authAkun).length && !!Object.keys(this.authPtk).length;
    },
  },
  created() {
    this.changeLayanan();
  },
  methods: {
    ...mapActions('preferensi', ['getLayanan', 'setBlokir']),

    toggleL() {
      this.$emit('toggleL');
    },
    toggleR() {
      this.$emit('toggleR');
    },

    popup(jenis) {
      this.$refs[jenis].open();
    },

    onLayanans() {
      if (this.layanans && this.layanans.length) {
        this.menu = true;
        return;
      }

      this.getLayanan().then(({ data }) => {
        const layanan = (data && data.layanan) || [];
        this.layanans = layanan.filter((x) => !x.arsip);
        this.arsips = layanan.filter((x) => x.arsip);
        this.menu = true;
      });
    },

    changeLayanan() {
      const tautan = [];
      const dapodikId = (this.ptk && this.ptk.dapodik_ptk_id) || false;
      const isPengawas = (this.ptk && this.ptk.is_pengawas) || false;
      if (
        !isPengawas &&
        this.role === 'gtk' &&
        dapodikId &&
        (!tautan.length || (tautan.length && !tautan.includes(3)))
      ) {
        this.setBlokir(true);
        // this.$router.push({ name: 'blokir' });
      }
    },
  },
};
</script>
<style scoped>
.logos {
  font-family: Roboto;
  font-weight: bold;
}
</style>
