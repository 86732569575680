export default {
  register: [
    {
      key: 'mahasiswa',
      program: 'Daftar Modul Mahasiswa',
      subheading: 'Modul Mahasiswa',
      submenu: false,
      menu: [
        {
          icon: 'mdi-open-in-new',
          title: 'Kelas Diklat',
          desc: 'Tautan Menuju SIM eLearning',
          color: 'cyan',
          deepColor: 'darken-2',
          akses: '#LMS_URL#',
          link: 'url-lms',
          href: '#LMS_URL#',
        },
        {
          icon: 'mdi-file-multiple',
          title: 'Berkas PKS',
          subtitle: '(Perjanjian Kerjasama)',
          desc: 'Berisi Berkas PKS (Perjanjian Kerjasama) Mahasiswa',
          color: 'yellow',
          deepColor: 'darken-2',
          akses: false,
          key: 'pks',
          to: { name: 'pks' },
        },
        {
          icon: 'mdi-file-arrow-left-right-outline',
          title: 'Pemutakhiran Data',
          desc: 'Pemutakhiran Data Diri Mahasiswa PPG Prajabatan',
          color: 'purple',
          deepColor: 'darken-2',
          akses: true,
          key: 'nrg',
          to: { name: 'nrg' },
        },
        {
          icon: 'mdi-badge-account-horizontal',
          title: 'Ujian PCK',
          desc: 'Detail Mahasiswa yang dinyatakan boleh mengikuti UKMPPG',
          color: 'error',
          deepColor: 'lighten-1',
          akses: false,
          key: 'pck',
          to: { name: 'pck' },
        },
        {
          icon: 'mdi-file-document-outline',
          title: 'Dokumen UMKPPG',
          subtitle: '',
          desc: 'Modul ini berisi tautan Perangkat Pembelajaran, Video Praktik Pembelajaran, Laporan Studi Kasus dan berkas ',
          color: 'info',
          deepColor: '',
          akses: false,
          disable: false,
          key: 'portofolio',
          to: { name: 'portofolio' },
        },
        {
          icon: 'mdi-teach',
          title: 'Wawancara UKMPPG',
          subtitle: '',
          desc: 'Seleksi (Wawancara) kandidat peserta PPG Prajabatan',
          color: 'pink',
          deepColor: 'darken-1',
          akses: false,
          key: 'wawancara-ukppg',
          to: { name: 'wawancara-ukppg' },
        },
        {
          icon: 'mdi-file-check',
          title: 'Data Sertifikat Pendidik',
          subtitle: '',
          desc: 'Modul Konfirmasi Data Sertifikat Pendidik Bagi Mahasiswa Lulus UKMPPG',
          color: 'pink',
          deepColor: 'darken-1',
          akses: false,
          key: 'serdik',
          to: { name: 'serdik' },
        },
      ],
    },
    {
      key: 'tahap3',
      program: 'Daftar Modul Tahap 3',
      subheading: 'Modul Tahap 3',
      submenu: false,
      menu: [
        {
          icon: 'mdi-clipboard-check-outline',
          title: 'Wawancara',
          subtitle: '',
          desc: 'Seleksi Tahap 3 (Wawancara) kandidat peserta PPG Prajabatan',
          color: 'pink',
          deepColor: 'darken-1',
          akses: false,
          key: 'wawancara',
          to: { name: 'wawancara' },
        },
      ],
    },
    {
      key: 'tahap2',
      program: 'Daftar Modul Tahap 2',
      subheading: 'Modul Tahap 2',
      submenu: false,
      menu: [
        {
          icon: 'mdi-badge-account-horizontal',
          title: 'Unduh Kartu Tes Substantif',
          desc: 'Unduh Kartu Tes Substantif untuk melaksanakan tahapan seleksi akademik Anda melalui menu ini',
          color: 'error',
          deepColor: 'lighten-1',
          akses: false,
          key: 'ujian',
          to: { name: 'ujian' },
        },
      ],
    },
    {
      key: 'tahap1',
      program: 'Daftar Modul Tahap 1',
      subheading: 'Modul Tahap 1',
      submenu: false,
      menu: [
        {
          icon: 'mdi-briefcase-account-outline',
          title: 'Biodata Diri',
          subtitle: '',
          desc: 'Pada langkah ini Anda diharuskan melengkapi semua informasi Biodata Diri Anda',
          color: 'orange',
          deepColor: '',
          akses: true,
          // disable: true,
          key: 'cv',
          // action: 'cv',
          to: { name: 'cv' },
        },
        {
          icon: 'mdi-square-edit-outline',
          title: 'Esai',
          subtitle: '',
          desc: 'Pada langkah ini Anda diharuskan menyelesaikan semua pertanyaan Esai yang telah disediakan',
          color: 'blue',
          deepColor: 'darken-2',
          akses: false,
          // disable: true,
          key: 'esai',
          to: { name: 'esai' },
        },
        {
          icon: 'mdi-map-marker',
          title: 'Preferensi Lokasi Pengabdian',
          subtitle: '',
          desc: 'Pada langkah ini Anda diharuskan menentukan preferensi Anda pada lokasi pengabdian dan perkuliahan',
          color: 'purple',
          deepColor: '',
          akses: false,
          // disable: true,
          key: 'penempatan',
          to: { name: 'penempatan' },
        },
        {
          icon: 'mdi-cash',
          title: 'Pembayaran',
          subtitle: '',
          desc: 'Aksi Pembayaran untuk melanjutkan ke tahap seleksi berikutnya',
          color: 'success',
          deepColor: '',
          akses: false,
          disable: false,
          key: 'bayar',
          to: { name: 'bayar' },
        },
        {
          icon: 'mdi-cash-multiple',
          title: 'Ajuan Pengembalian Pembayaran (Refund)',
          subtitle: '',
          desc: 'Fitur Ajuan Pengembalian Dana Pembayaran (Refund) dari seleksi PPG Prajabatan',
          color: 'purple',
          deepColor: 'darken-2',
          akses: false,
          disable: false,
          key: 'refund',
          to: { name: 'refund' },
        },
      ],
    },
    {
      key: 'other',
      program: 'Program/Layanan',
      subheading: 'Program/Layanan',
      submenu: false,
      menu: [
        {
          key: 'mutu',
          icon: 'mdi-frequently-asked-questions',
          title: 'Penjaminan Mutu PPG',
          desc: 'Link menuju Program Penjaminan Mutu PPG',
          color: 'teal',
          deepColor: 'darken-2',
          akses: false,
          href: '#MUTU_URL#',
        },
      ],
    },
    {
      icon: 'mdi-webhook',
      title: 'ke Aplikasi SIMPKB',
      desc: '',
      color: 'secondary',
      deepColor: 'darken-2',
      sidebar: true,
      akses: true,
      link: 'app-sim-pkb',
      href: '#SIM_PKB_URL#',
    },
  ],
  unregister: [
    {
      key: 'profil',
      program: 'Profil Diri',
      subheading: 'Profil Diri',
      submenu: false,
      menu: [
        {
          icon: 'mdi-briefcase-account-outline',
          title: 'Biodata Diri',
          subtitle: '',
          desc: 'Pada langkah ini Anda diharuskan melengkapi semua informasi Biodata Diri Anda',
          color: 'orange',
          deepColor: '',
          akses: true,
          // disable: true,
          key: 'cv',
          // action: 'cv',
          to: { name: 'cv' },
        },
      ],
    },
    {
      key: 'mahasiswa',
      program: 'Daftar Modul',
      subheading: 'Modul Diklat',
      submenu: false,
      menu: [
        {
          icon: 'mdi-open-in-new',
          title: 'Kelas Diklat',
          desc: 'Tautan Menuju SIM eLearning',
          color: 'cyan',
          deepColor: 'darken-2',
          akses: '#LMS_URL#',
          link: 'url-lms',
          href: '#LMS_URL#',
        },
      ],
    },
    {
      key: 'other',
      program: 'Program/Layanan',
      subheading: 'Program/Layanan',
      submenu: false,
      menu: [
        {
          key: 'mutu',
          icon: 'mdi-frequently-asked-questions',
          title: 'Penjaminan Mutu PPG',
          desc: 'Link menuju Program Penjaminan Mutu PPG',
          color: 'teal',
          deepColor: 'darken-2',
          akses: false,
          href: '#MUTU_URL#',
        },
      ],
    },
    {
      icon: 'mdi-webhook',
      title: 'ke Aplikasi SIMPKB',
      desc: '',
      color: 'secondary',
      deepColor: 'darken-2',
      sidebar: true,
      akses: true,
      link: 'app-sim-pkb',
      href: '#SIM_PKB_URL#',
    },
  ],
  lulus: [],
};
