import Vue from 'vue';
import Router from 'vue-router';
import store from '@store';
import routesAuth from './routes/auth';
import MenusGtk from '@configs/menus.gtk';
import MenusIns from '@configs/menus.instansi';
import { getDeepObj, isArray, isObject } from '@/utils/format';

const roleMenus = {
  gtk: MenusGtk,
  instansi: MenusIns,
};

Vue.use(Router);

const createRouter = () =>
  new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...routesAuth],
  });

const router = createRouter();

export function resetRouter(newModules) {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
  router.addRoutes(newModules);
}

let initRoute = false;
// Before each route evaluates
router.beforeEach((to, from, next) => {
  // Semua bisa akses, karena page yang dituju tidak perlu login
  if (to.meta?.public) return next();

  // Page yang perlu login
  // perlu pengecekan user login
  async function isUserLogin() {
    const logon = await store.dispatch('auth/checkUser');
    return logon;
  }

  async function getPreferensi(id) {
    const preferensi = await store.dispatch('auth/userPreferensi', id);
    return preferensi;
  }

  function getMenuGtk(preferensi) {
    // Belum register
    const isAsesor = isObject(preferensi?.asesor ?? {});
    // const isMahasiswa = isObject(preferensi?.mahasiswa ?? {});
    const isPengampus = isArray(preferensi?.pengampus ?? []);
    const isRegistered = isAsesor ? isObject(preferensi?.asesor ?? {}) : isObject(preferensi?.peserta ?? {});
    const peserta = preferensi?.peserta ?? {};
    const pesertaStatus = preferensi?.peserta_status ?? {};

    // allow menu ke mutu [dosen, pamong, instruktur]
    const allowMutuPengampu =
      (preferensi?.pengampus ?? []).filter((item) => [1, 2, 5].includes(item.k_pengampu_ppg)).length > 0;

    if (!isRegistered && !isPengampus) return [];

    const roleMenu = (roleMenus['gtk'] && roleMenus['gtk'][isRegistered ? 'register' : 'unregister']) || [];
    const menus = roleMenu.map((item) => {
      const configMutu = preferensi?.['config-mutu'] ?? {};
      const configMhs = preferensi?.['mahasiswa_status'] ?? {};
      const mahasiswa = preferensi?.['mahasiswa'] ?? {};
      let temp = Object.assign({}, item);

      // check temp memiliki menu
      if (temp.menu && temp.menu.length) {
        temp.menu.forEach((submenu) => {
          // buka akses esai
          if (submenu.key === 'esai' && !isAsesor) submenu.akses = true;
          // buka akses penempatan
          if (submenu.key === 'penempatan' && !isAsesor && +peserta.tahun >= 2024) submenu.akses = true;
          // tutup akses bayar buat asesor
          if (submenu.key === 'bayar' && !isAsesor)
            submenu.akses = (pesertaStatus && pesertaStatus.is_bayar_menu) || false;
          if (submenu.key === 'refund' && !isAsesor)
            submenu.akses = (pesertaStatus && pesertaStatus.is_refund_menu) || false;
          if (submenu.key === 'ujian' && !isAsesor)
            submenu.akses =
              (+(peserta && peserta.lulus_tahap) >= 1 && isObject(peserta && peserta.ppgpj_profil_ujian)) || false;
          if (submenu.key === 'wawancara' && !isAsesor) submenu.akses = +(peserta && peserta.lulus_tahap) >= 2 || false;
          if (submenu.key === 'wawancara-ukppg') submenu.akses = configMhs?.is_menu_ukppg ?? false;
          if (submenu.key === 'serdik') submenu.akses = configMhs?.is_serdik ?? false;

          if (submenu.key === 'pck' && !isAsesor) submenu.akses = configMhs?.is_ujian_pck ?? false;
          if (submenu.key === 'pks' && !isAsesor) submenu.akses = configMhs?.is_menu_pks ?? false;
          if (submenu.key === 'nrg' && !isAsesor) submenu.akses = configMhs?.is_menu_nrg ?? false;
          if (submenu.key === 'portofolio' && !isAsesor)
            submenu.akses = (mahasiswa && mahasiswa.tahun < 2023 && configMhs?.is_menu_portofolio) ?? false;

          if (submenu.href === '#LMS_URL#') {
            submenu.akses = getDeepObj(preferensi, 'elearning_url') ? true : false;
            submenu.href = submenu.href.replace(/#LMS_URL#/g, preferensi?.elearning_url);
          }

          if (submenu.href === '#MUTU_URL#') {
            const isAktif = Number(getDeepObj(pesertaStatus, 'mahasiswa.is_aktif')) === 1;
            const isLapor = Number(getDeepObj(pesertaStatus, 'mahasiswa.is_lapor')) === 1;
            const isUndur = Number(getDeepObj(pesertaStatus, 'mahasiswa.is_undur')) === 0;
            submenu.akses = allowMutuPengampu || (isAktif && isLapor && isUndur) ? true : false;
            submenu.href = submenu.href.replace(/#MUTU_URL#/g, getDeepObj(configMutu, 'sim-mutu-url') || '#');
          }
        });
      }

      // replace href
      temp = mapHrefurl(temp, preferensi);
      return temp;
    });
    return menus;
  }

  function getMenuIns(preferensi) {
    const roleMenu = roleMenus['instansi'];

    const menus = roleMenu.map((item) => {
      let temp = Object.assign({}, item);

      // replace href on menu
      if (temp && temp.menu && temp.menu.length) {
        temp.menu = temp.menu.map((object) => {
          return mapHrefurl(object, preferensi);
        });
      }

      // replace href
      temp = mapHrefurl(temp, preferensi);

      return temp;
    });
    return menus;
  }

  function mapHrefurl(temp, preferensi) {
    const role = store.state.auth.role;
    const akses = Object.keys((preferensi && preferensi.akses) || {});
    const groups = Object.keys(preferensi?.groups ?? {});

    if (temp && temp.key === 'diklat') {
      temp.akses =
        role === 'gtk'
          ? !!getDeepObj(preferensi, 'elearning.diklat')
          : (typeof temp.akses === 'boolean' ? temp.akses : akses.includes(temp && temp.akses)) &&
            ![false, null, ''].includes(preferensi?.elearning?.diklat);
    }

    if (temp && temp.key === 'dkp-pa' && role === 'gtk') {
      temp.akses = (preferensi && preferensi.pelatih_ahli_status && preferensi.pelatih_ahli_status.is_kp_pa) || false;
    }

    if (temp && temp.key === 'sertifikat') {
      temp.akses = (preferensi && preferensi.sertifikat) || false;
    }

    if (temp && temp.href) {
      const configMutu = preferensi?.['config-mutu'] ?? preferensi?.['mutu'] ?? {};

      if (temp.href === '#LMS_URL#') {
        temp.akses = getDeepObj(preferensi, 'elearning_url') ? true : false;
      }

      if (temp.href === '#LMS_PMM_URL#') {
        temp.akses = getDeepObj(preferensi, 'elearning_pmm_url') ? true : false;
      }

      if (temp.href === '#MUTU_URL#') {
        temp.akses =
          groups.includes('97') || getDeepObj(configMutu, 'sim-mutu-url') || getDeepObj(configMutu, 'url')
            ? true
            : false;
      }

      temp.href = temp.href.replace(
        /#PORTAL_URL#/g,
        'https://sekolah.penggerak.kemdikbud.go.id/programsekolahpenggerak/'
      );
      temp.href = temp.href.replace(/#SIM_PKB_URL#/g, preferensi?.simpkb);
      temp.href = temp.href.replace(/#LMS_URL#/g, preferensi?.elearning_url);
      temp.href = temp.href.replace(/#LMS_PMM_URL#/g, preferensi?.elearning_pmm_url);
      temp.href = temp.href.replace(
        /#BI_URL#/g,
        (preferensi && preferensi.bi && preferensi.bi['instrumen-sekolah']) || '#'
      );
      temp.href = temp.href.replace(
        /#MUTU_URL#/g,
        getDeepObj(configMutu, 'sim-mutu-url') || getDeepObj(configMutu, 'url') || '#'
      );
    }

    // recheck akses
    if (temp && temp.key === 'bi') {
      temp.disable = preferensi && preferensi.bi && preferensi.bi['instrumen-sekolah'] ? false : true;
    }

    return temp;
  }

  async function setMenus(preferensi) {
    if (store.state.menus && store.state.menus.length) return store.state.menus;

    // get menu by role dan tahap
    const role = store.state.auth.role;
    const menus = role === 'gtk' ? getMenuGtk(preferensi) : getMenuIns(preferensi);
    // store menus
    await store.commit('SET_MENUS', menus);
    return menus;
  }

  function getObjMenu(menus, name) {
    if (!name || !menus.length) return {};

    const parent = menus.filter((item) => item.key === name);
    if (parent.length) return parent && parent[0];

    let children = [];
    let i = 0;
    for (i; i < menus.length; i++) {
      if (menus[i]?.children) children.push(menus[i]?.children);
    }
    if (children.length) return children.filter((item) => item.key === name)[0] ?? {};
  }

  function redirectToLogin() {
    window.location.href = process.env.VUE_APP_API_URL + `/auth/login`;
  }

  isUserLogin()
    .then(async () => {
      const role = store.state.auth.role;

      // register route
      if (!initRoute) {
        const modules = await import(`./routes/${role}`);
        resetRouter(modules.default);
        initRoute = true;
      }

      // to akses tidak perlu id
      if (to.name === 'access') return next();

      // normalisasi path setelah login
      const path = to.redirectedFrom ?? to.fullPath;

      const routeId = (to.params && to.params.id) || (path.match(/\d+/g) && path.match(/\d+/g)[0]) || '';
      const id = routeId || store.getters['auth/instansiId'];

      const preferensi = await getPreferensi(id);
      const menus = await setMenus(preferensi);
      const currMenu = getObjMenu(menus, to.name);
      const params = role === 'instansi' ? { id } : {};

      // cek isBlokir
      // const isBlokir = store.state.preferensi.isBlokir || false;
      // if (role !== 'instansi' && isBlokir && to.name !== 'blokir') return next({ name: 'blokir' });
      // if ((to.name === 'blokir' || (path || '').indexOf('blokir') > -1) && !isBlokir) return next({ name: 'home' });

      // cek akses menu yang dituju
      if (role === 'instansi' && !routeId) return next({ name: 'home', params });
      if (isObject(currMenu) && (!currMenu?.akses || currMenu?.disable)) return next({ name: 'home', params });
      return to.name
        ? next()
        : role === 'instansi' && ['/', `/i/${id}/home`].includes(path)
        ? next({ name: 'home', params })
        : next(path);
    })
    .catch((error) => {
      if (error.message === 'User belum login!') {
        redirectToLogin();
      } else if (error.redirect) {
        window.location.href = error.redirect;
      } else {
        Vue.prototype.$error(
          `Mohon maaf, ${error?.message ?? 'data preferensi Anda gagal dimuat'}. Silakan muat ulang halaman!`,
          [
            {
              label: 'Muat Ulang',
              event: () => {
                window.location.reload();
              },
            },
          ]
        );
      }
    });
});

router.beforeResolve(async (to, from, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of to.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(to, from, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (from.name === args[0].name) {
                // Complete the animation of the route progress bar.
              }
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

// When each route is finished evaluating...
router.afterEach(() => {
  // Complete the animation of the route progress bar.
  store.commit('SET_SPLASH', false);
});

export default router;
